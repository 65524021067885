<template>
  <section class="login-content bg-login-img">
    <!-- <img src="../../../assets/images/login/02.png" class="lb-img" alt="">
         <img src="../../../assets/images/login/03.png" class="rb-img" alt=""> -->
    <div class="container h-100">
      <b-row class="align-items-center justify-content-center h-100">
        <div class="col-12">
          <b-row class="align-items-center">
            <div class="col-lg-6 mb-lg-0 mb-4 mt-lg-0 mt-4 responsive-xl-md">
              <img src="/img/HXWhite.png" style="" class="img-fluid w-80 display-dark" alt="" />
              <img src="/img/HXWblack.png" class="img-fluid w-80 display-light" alt="" />
              <!-- <img src="../../../assets/images/login/01.png" class="img-fluid w-80" alt="" /> -->
            </div>
            <b-col lg="6 " class="card p-5" style="border-radius: 5px">
              <!-- <img src="/img/HXLogoWhite.png" class="img-fluid rounded-normal " alt="logo" style="width: 100%; " /> -->
              <!-- <img src="/img/HXLogoBlack.png" class="img-fluid rounded-normal display-light" alt="logo" style="width: 90%" /> -->
              <img class="mb-sm-2 mb-0 p-md-5 display-dark" src="/img/HXLogoWhite.png" alt="" />
              <img class="mb-sm-2 mb-0 p-md-5 display-light" src="/img/HXLogoBlack.png" alt="" />

              <!-- <p>To Keep connected with us please login with your personal info.</p> -->
              <!-- <form @submit.prevent="login"> -->
              <form @submit.prevent="loginGetEmpresaPersona()">
                <b-row>
                  <b-col lg="12" v-if="paso2 == false">
                    <label class="ml-1">Email</label>
                    <div class="floating-label form-group">
                      <input required class="form-control" id="email" type="email" placeholder=" " v-model="Usuario" />
                    </div>
                    <button type="submit" class="btn-block btn-secondary py-2">Ingresar</button>
                  </b-col>
                </b-row>
              </form>

              <form @submit.prevent="loginGetPersona()">
                <b-row>
                  <b-col lg="12" v-if="paso2 == true">
                    <label class="ml-1">Ingrese el código de acceso que se envió a su correo electrónico:</label>
                    <div class="floating-label form-group">
                      <input v-on:keyup.enter="onEnter()" required class="form-control" type="password" placeholder=" " v-model="Contrasena" />
                    </div>
                    <div v-if="terminosYCondiciones.hasOwnProperty('texto')" class="center mb-3" style="display: flex">
                      <vs-checkbox required v-model="aceptoTerminos"> </vs-checkbox>
                      <span class="ml-3 pointer" @click="checkAceptoTerminos()">Términos y condiciones y aviso de privacidad</span>
                    </div>
                    <button type="submit" class="btn-block btn-secondary py-2">Ingresar</button>
                  </b-col>

                  <!-- <b-col lg="6" >
                                 <div class="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" class="custom-control-input" id="customCheck1">
                                    <label class="custom-control-label text-light mp-1" for="customCheck1">Recordar</label>
                                 </div>
                              </b-col> -->
                  <!-- <b-col lg="6" >
                                 <router-link to="/auth/recover-password" class="text-light float-right">¿Olvidó su contraseña?</router-link>
                              </b-col> -->
                </b-row>
              </form>
              <span class="mb-2">{{ mensaje }}</span>

              <p class="mt-3 text-light">
                <!-- Create an Account <router-link to="/auth/sign-up" class="text-primary">Sign Up</router-link> -->
              </p>
              <div class="row">
                <div class="col-12 text-center mt-5">
                  <span class="text-light pt-5">20241011</span><br />
                  <span class="text-light pt-5">Helexium ® 2024</span>
                </div>
              </div>
              <!-- </form> -->
            </b-col>
          </b-row>
        </div>
      </b-row>
    </div>
    <b-modal id="tyc-modal" title="Términos y Condiciones" size="xl" hide-footer>
      <div class="row px-5">
        <div class="col-md-12 content ql-editor" v-html="terminosYCondiciones.texto"></div>
      </div>
    </b-modal>
  </section>
</template>
<script>
import Swal from "sweetalert2";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "SignIn",
  data() {
    return {
      Usuario: "", // "admindemo@testing.com", // "RRHH4@testing.com", //
      Contrasena: "",
      mensaje: "",
      paso2: false,
      PersonaAct: {},
      terminosYCondiciones: {},
      aceptoTerminos: false,
    };
  },
  mounted() {
    localStorage.removeItem("setPersonaAct");

    // limpiar datos de autenticacion de sisi
    localStorage.removeItem("sisiAuth");

    const isDarkModeActive = JSON.parse(localStorage.getItem("isdark")) == null ? false : JSON.parse(localStorage.getItem("isdark"));
    const body = document.querySelector("body");

    if (isDarkModeActive) {
      // document.body.classList.add("dark");
      body.classList.add("dark");
    } else {
      // alert(isDarkModeActive)
      body.classList.remove("dark");
    }
  },
  computed: {
    ...mapState("pesv", ["credential"]),
  },
  methods: {
    ...mapMutations("pesv", ["setSisiAuth"]),
    ...mapActions("pesv", ["useRequestSisi"]),
    async authSisi() {
      // Autenticar con Sisi
      const authResponse = await this.useRequestSisi({
        method: "POST",
        path: "autenticacion/inicio-sesion",
        body: this.credential,
      });

      if (!authResponse.ok) {
        return;
      }

      // Guardar credenciales en el store y en localStorage
      this.setSisiAuth(authResponse.data);
    },
    checkAceptoTerminos() {
      this.$bvModal.show("tyc-modal");
    },
    async getTerminosYCondiciones(id) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "TerminosYCondiciones/GetTerminosYCondicionesPersonaPaisId/" + id,
        });
        this.terminosYCondiciones = res;
      } catch (error) {
        this.terminosYCondiciones = {};
      }
    },
    async onEnter() {
      await this.loginGetPersona();
    },
    async loginGetPersona() {
      let data = { Usuario: this.Usuario, Contrasena: this.Contrasena };
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("hl_post", {
          path: "Authentication/LoginPersona",
          data: data,
        });

        if (res.id) {
          localStorage.setItem(
            "credenciales",
            JSON.stringify({
              personaId: res.id,
              dynamicPassword: this.Contrasena,
            })
          );

          this.PersonaAct = res;
          let tokenres = await this.$store.dispatch("posthlw", {
            path: "Authentication/GetToken",
            data: data,
          });
          this.$isLoading(false);

          //tokenres = await tokenres.json();
          this.$store.commit("setToken", tokenres.token);
          this.$store.commit("setPersonaAct", res);
          localStorage.setItem("tk", tokenres.token);
          localStorage.setItem("user", JSON.stringify(res.data));
          this.$store.dispatch("wrapperSetUserLoggedIn", res);

          //aceptando terminos y condiciones
          let dataTerminos = {
            PersonaId: res.id,
            fecha: this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDTHH:mm"),
          };
          await this.$store.dispatch("hl_post", {
            path: "TerminosYCondiciones/AceptaTerminosYCondiciones/",
            data: dataTerminos,
          });
          this.$router.push("dashboard");
          this.mensaje = "";
          //return true;
        } else {
          this.PersonaAct = {};
          this.$isLoading(false);
          Swal.fire("Credenciales incorrectas!", "", "warning");

          //this.mensaje = "Credenciales incorrectas.";
          //return false;
        }

        // autenticar con sisi
        await this.authSisi();
      } catch (error) {
        console.log("error", error);
        this.$isLoading(false);
        this.PersonaAct = {};
        //return false;
      }
    },
    async loginGetEmpresaPersona() {
      this.$isLoading(true);
      // let data = JSON.stringify({ Correo: this.Usuario});
      try {
        let res = await this.$store.dispatch("gethlw", {
          path: "Persona/GetEmpresaPersona/" + this.Usuario,
        });
        this.$isLoading(false);
        console.log("loginGetEmpresaPersona", res);
        if (res.id) {
          this.PersonaAct = res;
          this.paso2 = true;
          this.mensaje = "";
          // this.$router.push("dashboard");
          //return true;
          await this.getTerminosYCondiciones(this.Usuario);
        } else {
          this.PersonaAct = {};
          this.mensaje = "Credenciales incorrectas.";
          //return false;
        }
      } catch (error) {
        this.$isLoading(false);
        this.PersonaAct = {};
        //return false;
      }
    },
    async login() {
      this.$isLoading(true);
      let data = JSON.stringify({
        Usuario: this.Usuario,
        contrasena: this.Contrasena,
      });
      try {
        let res = await this.$store.dispatch("hl_login", { data: data });
        this.$isLoading(false);
        //console.log("rs", res);
        if (res.id) {
          this.PersonaAct = res;
          this.$router.push("dashboard");
          //return true;
        } else {
          this.PersonaAct = {};
          this.mensaje = "Credenciales incorrectas.";
          //return false;
        }
      } catch (error) {
        this.$isLoading(false);
        this.PersonaAct = {};
        //return false;
      }
    },
  },
};
</script>
